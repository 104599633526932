import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultCard from "components/Card";

// Images
import edgiaimlsomwifiimg from "assets/images/products/SOM/HomePage/XENO-LORA_update.jpg";
import tmlsomimg from "assets/images/products/SOM/HomePage/TinyMLSOm.png";

import visionmlsomimg from "assets/images/products/SOM/HomePage/XNO-VISION-ML-SOM-TOP .png";

// import edgiaimlsomloraimg from "assets/images/products/SOM/HomePage/XENO - LORA.png";
import edgiaimlsomlteimg from "assets/images/products/SOM/HomePage/XENO-LTE_update.jpg";
// import edgiaimlsomnbiotimg from "assets/images/products/SOM/HomePage/XENO - NBIOT.png";
import evkitwifiimg from "assets/images/products/EVKKit/wifi_evk_kit.png";
import tmlevkimg from "assets/images/products/EVKKit/tml_evk.png";
// import evkitloraimg from "assets/images/products/EVKKit/lora_evk_kit.png";
import evkitlteimg from "assets/images/products/EVKKit/lte_evk_kit.png";
// import evkitnbiotimg from "assets/images/products/EVKKit/nbiot_evk_kit.png";
import mlmodelhasimg from "assets/images/SVG/Human Alert.svg";
import mlmodelpestimg from "assets/images/SVG/Pests sound.svg";
import mlmodelvsimg from "assets/images/SVG/Vibration Sensor.svg";
import swdevsdkimg from "assets/images/SVG/SDK.svg";
import swdevdmsimg from "assets/images/SVG/Device Management.svg";
import swdevciotdimg from "assets/images/SVG/Dashboard.svg";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function Product() {
  // const textRef = useRef(null);

  // useEffect(() => {
  //   if (textRef.current) {
  //     // Split text into characters for individual animation
  //     const chars = textRef.current.innerText.split('');
  //     textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

  //     // Animate each character with GSAP
  //     gsap.fromTo(textRef.current.children,
  //       { opacity: 0, y: 50 },
  //       { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
  //     );
  //   }
  // }, []);

  gsap.registerPlugin(ScrollTrigger);
  const sectionsRef = useRef([]);

  useEffect(() => {
    sectionsRef.current.forEach(section => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: section,
            start: 'top 80%',
            toggleActions: 'play none none none',
          },
        }
      );
    });
  }, []);

  const products = [
    {
      id: 1,
      component:

        <MKBox component="section" py={2} id="edgeaimlsom">
          <Container>
            <Grid container item xs={12} lg={12} mx={1} justifyContent="center">
              <MKTypography
                variant="h4"
                color="dark"
                textAlign="center"
                textTransform="capitalize"
              // sx={({ breakpoints, typography: { size } }) => ({
              //     [breakpoints.down("md")]: {
              //         fontSize: size["3xl"],
              //     },
              // })}
              >
                Vision ML System-On-Modules (SOMs)
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} pt={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <Grid item xs={12} md={4} lg={4} px={2} sx={{ justifyContent: "center" }}>
    <DefaultCard
      bgcolor="#04293A"
      title="XENO+ Vision ML SOM (R2L100)"
      image={visionmlsomimg}
      imagesize="15rem"
      imagewidthsize="15rem"
      heightValue="24rem"
      widthValue="20rem"
      buttonlink="/website/productpage/vmlsom"
      buttonvalue="Find out more"
    />
  </Grid>
</Grid>

          </Container>
        </MKBox>
    },
    {
      id: 2,
      component:

        <MKBox component="section" py={2} id="edgeaimlsom">
          <Container>
            <Grid container item xs={12} lg={12} mx={1} justifyContent="center">
              <MKTypography
                variant="h4"
                color="dark"
                textAlign="center"
                textTransform="capitalize"
              // sx={({ breakpoints, typography: { size } }) => ({
              //     [breakpoints.down("md")]: {
              //         fontSize: size["3xl"],
              //     },
              // })}
              >Edge AIML System-On-Modules (SOMs)
               
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
              <Grid item xs={12} md={4} lg={4} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+  Tiny ML SOM (TML120)"
                  image={tmlsomimg}
                  imagesize="15rem"
                  imagewidthsize="12rem"
                  heightValue="24rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/tmlsom"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+ ML SOM with WiFi+BLE and LoRa (WN100)"
                  image={edgiaimlsomwifiimg}
                  imagesize="15rem"
                  imagewidthsize="12rem"
                  heightValue="24rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/wifisom"
                  buttonvalue="Find out more"
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={3} px={2}>
            <DefaultCard
              bgcolor="#04293A"
              title="XENO+ ML SOM with LTE Cellular Connectivity"
              image={edgiaimlsomloraimg}
              imagesize="15rem"
              imagewidthsize="12rem"
              heightValue="24rem"
              widthValue="20rem"
              buttonlink="/website/productpage/lorasom"
              buttonvalue="Find out more"
            />
</Grid>*/}
              <Grid item xs={12} md={4} lg={4} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+ ML SOM with LTE Cellular Connectivity (LN100)"
                  image={edgiaimlsomlteimg}
                  imagesize="15rem"
                  imagewidthsize="12rem"
                  heightValue="24rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/nbiotsom"
                  buttonvalue="Find out more"
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6} px={2}>
            <DefaultCard
              bgcolor="#04293A"
              title="XENO+ ML SOM with LTE Cellular Connectivity"
              image={edgiaimlsomlteimg}
              imagesize="15rem"
              imagewidthsize="12rem"
              heightValue="24rem"
              widthValue="20rem"
              buttonlink="/website/productpage/ltesom"
              buttonvalue="Find out more"
            />
          </Grid> */}
            </Grid>
          </Container>
        </MKBox>
    },
    {
      id: 3,
      component:
        <MKBox component="section" py={2} id="edgeaimlevkkit">
          <Container>
            <Grid container item xs={12} lg={12} mx={1} justifyContent="center">
              <MKTypography variant="h4" color="dark" textAlign="center" textTransform="capitalize">
                Edge AIML Evaluation Kit (EVK) Kits
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={4} lg={4} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+ Tiny ML Module EVK Kit (TML120 )"
                  image={tmlevkimg}
                  imagesize="12rem"
                  imagewidthsize="14rem"
                  heightValue="24rem"
                  widthValue="22rem"
                  buttonlink="/website/productpage/tmlevk"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+ EVK Kit for WiFi+BLE and LoRa ML SOM (WN100)"
                  image={evkitwifiimg}
                  imagesize="12rem"
                  imagewidthsize="14rem"
                  heightValue="24rem"
                  widthValue="22rem"
                  buttonlink="/website/productpage/wifievkkit"
                  buttonvalue="Find out more"
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={3} px={2}>
              <DefaultCard
                bgcolor="#04293A"
                title="XENO+ LoRa ML EVK Kit"
                image={evkitloraimg}
                imagesize="12rem"
                imagewidthsize="14rem"
                heightValue="24rem"
                widthValue="22rem"
                buttonlink="/website/productpage/loraevkkit"
                buttonvalue="Find out more"
              />
            </Grid> */}
              <Grid item xs={12} md={4} lg={4} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+ EVK Kit for LTE Cellular Connectivity (LN100)"
                  image={evkitlteimg}
                  imagesize="12rem"
                  imagewidthsize="14rem"
                  heightValue="24rem"
                  widthValue="22rem"
                  buttonlink="/website/productpage/nbiotevkkit"
                  buttonvalue="Find out more"
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={3} px={2}>
              <DefaultCard
                bgcolor="#04293A"
                title="XENO+ LTE ML EVK Kit"
                image={evkitlteimg}
                imagesize="12rem"
                imagewidthsize="14rem"
                heightValue="24rem"
                widthValue="22rem"
                buttonlink="/website/productpage/lteevkkit"
                buttonvalue="Find out more"
              />
            </Grid> */}
            </Grid>
          </Container>
        </MKBox>
    },
    {
      id: 4,
      component:
        <MKBox component="section" py={2} id="edgeaimlswdev">
          <Container>
            <Grid container item xs={12} lg={12} mx={1} justifyContent="center">
              <MKTypography variant="h4" color="dark" textAlign="center" textTransform="capitalize">
                SW Development Support
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
              <Grid item xs={0} md={0} lg={1.5} px={2}></Grid>
              <Grid item xs={12} md={12} lg={3} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="XENO+ SDK For Networking And ML APIs"
                  image={swdevsdkimg}
                  imagesize="8rem"
                  heightValue="20rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/xsdk"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="FOTA Server for Auth and FOTA"
                  image={swdevdmsimg}
                  imagesize="8rem"
                  heightValue="20rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/dms"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="Customizable IoT DASHBOARD"
                  image={swdevciotdimg}
                  imagesize="8rem"
                  heightValue="20rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/ciotdb"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={0} md={0} lg={1.5} px={2}></Grid>
            </Grid>
          </Container>
        </MKBox>
    },
    {
      id: 5,
      component:
        <MKBox component="section" py={2} id="edgeaimlmodel">
          <Container>
            <Grid container item xs={12} lg={12} mx={1} justifyContent="center">
              <MKTypography variant="h4" color="dark" textAlign="center" textTransform="capitalize">
                ML Models
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
              <Grid item xs={0} md={0} lg={1.5} px={2}></Grid>
              <Grid item xs={12} md={12} lg={3} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="Human Alert Sound Models"
                  image={mlmodelhasimg}
                  imagesize="8rem"
                  heightValue="18rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/hasm"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="Pests Sound Models"
                  image={mlmodelpestimg}
                  imagesize="8rem"
                  heightValue="18rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/psm"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3} px={2}>
                <DefaultCard
                  bgcolor="#04293A"
                  title="Vibration Sensor Models"
                  image={mlmodelvsimg}
                  imagesize="8rem"
                  heightValue="18rem"
                  widthValue="20rem"
                  buttonlink="/website/productpage/vsm"
                  buttonvalue="Find out more"
                />
              </Grid>
              <Grid item xs={0} md={0} lg={1.5} px={2}></Grid>
            </Grid>
          </Container>
        </MKBox>
    },
  ]
  return (
    <div>
      <MKBox mt={10}>
        <MKBox component="section" py={2} id="product">
          <Container>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={4} lg={4} justifyContent="center">
                <MKTypography
                  variant="Overline"
                  color="dark"
                  textTransform="capitalize"
                  fontWeight="light"
                // sx={({ breakpoints, typography: { size } }) => ({
                //     [breakpoints.down("md")]: {
                //         fontSize: size["xl"],
                //     },
                // })}
                >
                  Eoxys
                </MKTypography>
                <MKTypography
                  variant="h2"
                  color="dark"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  our Products
                </MKTypography>
              </Grid>
              <Grid item xs={12} md={8} lg={8} justifyContent="center">
                <MKTypography variant="body1" color="dark" textAlign="justify" fontWeight="regular">
                  We Take Pride In Enabling our clients to Build their Edge AIML devices
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>

        <div>
          {products?.map((comp, i) => (
            <div
              key={comp.id}
              ref={el => sectionsRef.current[i] = el}
            // style={{
            //   height: '100vh',
            //   backgroundColor: i % 2 === 0 ? 'lightcoral' : 'lightblue',
            //   display: 'flex',
            //   alignItems: 'center',
            //   justifyContent: 'center',
            //   fontSize: '2rem',
            //   color: '#fff',
            // }}
            >
              {comp.component}
            </div>
          ))}
        </div>


      </MKBox>
    </div>
  );
}

export default Product;
