
import Product_Page from "pages/Website/components/Product_Page";
// import Store_page from "pages/Website/components/Store_Page";
import Success_Stories_Page from "pages/Website/components/Success_Stories_Page";

const sw = (
  <p>
    Helps our clients to ease their
    <br /> SW development
  </p>
);
const soms = (
  <p>
    Solderable Edge AIML SOMs<br /> with different wireless connectivity
  </p>
);

const vsoms = (
  <p>
   
AI-powered solderable vision ML module.
  </p>
);



// const rtu = (
//   <p>
//     Clients to carry-out quick <br />
//     POCs of their target applications
//   </p>
// );
const evkkit = (
  <p>
    Edge AIML SOM EVK kits <br />
  </p>
);
const prodEng = (
  <p>
    We build your AIML/IoT products <br />with ML SOM modules or Custom <br />HW design.
  </p>
);
const SWEng = (
  <p>
    We build your Cloud and Desktop <br />applications with latest tech stack
  </p>
);
const mlmodels = (
  <p>
    Ready ML models enables
    <br /> the clients to quickly port
    <br /> and test these models
  </p>
);
const casess = (
  <p>
    See all our case studies
  </p>
);
const prodss = (
  <p>
    See all our Product
    <br /> Engineering success stories
  </p>
);
const cloudswss = (
  <p>
    See all our Cloud SW
    <br /> Engineering success stories
  </p>
);

const routes1 = [
  {
    name: "Home",
    // icon: <GitHubIcon />,
    href: "#/website",
  },
  {
    name: "Products",
    route: "/website#product",
    collapse: [
      {
        name: "Vision ML System-On-Modules (SOMs)",
        route: "/website#edgeaimlsom",
        description: vsoms,
        dropdown: true,
        collapse: [

          {
            name: "XENO+ Vision ML SOM",
            route: "/website/productpage/vmlsom",
            component: <Product_Page />,
          },
          
        ],
      },
      {
        name: "Edge AIML System-On-Modules (SOMs)",
        route: "/website#edgeaimlsom",
        href: "/website",
        hash: "edgeaimlsom",
        description: soms,
        dropdown: true,
        collapse: [

          {
            name: "XENO+  Tiny ML SOM",
            route: "/website/productpage/tmlsom",
            component: <Product_Page />,
          },
          {
            name: "XENO+ ML SOM with WiFi+BLE and LoRa",
            route: "/website/productpage/wifisom",
            component: <Product_Page />,
          },
          // {
          //   name: "XENO+ LoRa ML SOM",
          //   route: "/website/productpage/lorasom",
          //   component: <Product_Page />,
          // },
          {
            name: "XENO+ ML SOM with LTE Cellular Connectivity",
            route: "/website/productpage/nbiotsom",
            component: <Product_Page />,
          },
          // {
          //   name: "XENO+ LTE ML SOM",
          //   route: "/website/productpage/ltesom",
          //   component: <Product_Page />,
          // },
        ],
      },
      {
        name: "Edge AIML Evaluation Kit (EVK) Kits",
        route: "/website#edgeaimlevkkit",
        description: evkkit,
        dropdown: true,
        collapse: [

          {
            name: "XENO+ Tiny ML SOM EVK Kit",
            route: "/website/productpage/tmlevk",
            component: <Product_Page />,
          },
          {
            name: "XENO+ EVK Kit for WiFi+BLE and LoRa ML SOM",
            route: "/website/productpage/wifievkkit",
            component: <Product_Page />,
          },
          // {
          //   name: "XENO+ LoRa ML EVK Kit",
          //   route: "/website/productpage/loraevkkit",
          //   component: <Product_Page />,
          // },
          {
            name: "XENO+ EVK Kit for LTE Cellular Connectivity",
            route: "/website/productpage/nbiotevkkit",
            component: <Product_Page />,
          },
          // {
          //   name: "XENO+ LTE ML EVK Kit",
          //   route: "/website/productpage/lteevkkit",
          //   component: <Product_Page />,
          // },
        ],
      },
      {
        name: "SW DEVELOPMENT SUPPORT",
        route: "/website#edgeaimlswdev",
        description: sw,
        dropdown: true,
        collapse: [
          {
            name: "XENO+ SDK For Networking And ML APIs",
            route: "/website/productpage/xsdk",
            component: <Product_Page />,
          },
          {
            name: "FOTA Server for Device Auth and FOTA",
            route: "/website/productpage/dms",
            component: <Product_Page />,
          },
          {
            name: "Customizable IoT DASHBOARD",
            route: "/website/productpage/ciotdb",
            component: <Product_Page />,
          },
        ],
      },
      {
        name: "ML MODELS",
        route: "/website#edgeaimlmodel",
        description: mlmodels,
        dropdown: true,
        collapse: [
          {
            name: "Human Alert Sound Models",
            route: "/website/productpage/hasm",
            component: <Product_Page />,
          },
          {
            name: "Pests Sound Models",
            route: "/website/productpage/psm",
            component: <Product_Page />,
          },
          {
            name: "Vibration Sensor Models",
            route: "/website/productpage/vsm",
            component: <Product_Page />,
          },
        ],
      },
    ],
  },
  {
    name: "Services",
    route: "/website#service",
    // icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Product Engineering",
        route: "/website#service",
        description: prodEng,
        collapse: [
        ],
      },
      {
        name: "Cloud Software Engineering",
        route: "/website#service",
        description: SWEng,
        collapse: [
        ],
      },
    ],
  },
  {
    name: "Company",
    route: "/website#aboutus",
  },
  {
    name: "Success Stories",
    route: "/successstories",
    // icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "IoT Product Engineering",
        route: "/successstories#iotpe",
        component: <Success_Stories_Page />,
        description: prodss,
        dropdown: true,
        collapse: [
          {
            name: "Wear Monitoring Device",
            route: "/successstories#iotpewmd",
            component: <Success_Stories_Page />,
          },
          {
            name: "Pest Sensor Device",
            route: "/successstories#iotpepsd",
            component: <Success_Stories_Page />,
          },
          {
            name: "BLE Beacon Gateway",
            route: "/successstories#iotpeblegw",
            component: <Success_Stories_Page />,
          },
          {
            name: "WiFi IoT Camera",
            route: "/successstories#iotpeiotcamera",
            component: <Success_Stories_Page />,
          },
          {
            name: "NB-IoT Based Audio Classification Device",
            route: "/successstories#iotpenacd",
            component: <Success_Stories_Page />,
          },
          {
            name: "Wifi Based Audio Classification Device",
            route: "/successstories#iotpewacd",
            component: <Success_Stories_Page />,
          },
          {
            name: "Road Quality Device",
            route: "/successstories#iotperqd",
            component: <Success_Stories_Page />,
          },
        ],
      },
      {
        name: "Cloud software Engineering",
        route: "/successstories#cloud",
        component: <Success_Stories_Page />,
        description: cloudswss,
        dropdown: true,
        collapse: [
          {
            name: "ECG Devices IoT Dashboard ",
            route: "/successstories#cloudecgiot",
            component: <Success_Stories_Page />,
          },
          {
            name: "ACD Devices IoT Dashboard",
            route: "/successstories#cloudacdiot",
            component: <Success_Stories_Page />,
          },
          {
            name: "Sensor Server",
            route: "/successstories#cloudsensorserver",
            component: <Success_Stories_Page />,
          },
          {
            name: "Tune App",
            route: "/successstories#cloudtuneapp",
            component: <Success_Stories_Page />,
          },
          {
            name: "Fuel Monitoring Server",
            route: "/successstories#cloudfms",
            component: <Success_Stories_Page />,
          },
          {
            name: "Bumper Glass",
            route: "/successstories#cloudbumperglass",
            component: <Success_Stories_Page />,
          },
        ],
      },
      {
        name: "Case Studies",
        route: "/successstories#casestudies",
        component: <Success_Stories_Page />,
        description: casess,
        dropdown: true,
        collapse: [
          {
            name: "IoT Wearable Watch For Patient Care",
            route: "/successstories#casestudiesiotpc",
            component: <Success_Stories_Page />,
          },
          {
            name: "IoT Cloud Server Application For Smart Locks",
            route: "/successstories#casestudiesiotsl",
            component: <Success_Stories_Page />,
          },
          {
            name: "Medi Watch Wearable For Sleep Apnea Diagnosis",
            route: "/successstories#casestudiesmwwsad",
            component: <Success_Stories_Page />,
          },
          {
            name: "Vibration Sensor Device",
            route: "/successstories#casestudiesvsd",
            component: <Success_Stories_Page />,
          },
          {
            name: "IoT Cloud Dashboard Server For Smart Factory Automation",
            route: "/successstories#casestudiesiotdb",
            component: <Success_Stories_Page />,
          },
          {
            name: "Eoxys Single Sign-On (SSO) Server Application",
            route: "/successstories#casestudiessso",
            component: <Success_Stories_Page />,
          },
        ],
      },
    ],
  },
  {
    name: "Insights",
    route: "/website#news",
    // icon: <GitHubIcon />,
    // href: "https://www.github.com/creativetimofficial/material-kit-react",
  },
  // {
  //   name: "Store",
  //   // icon: <GitHubIcon />,
  //   route: "/store#store",
  //   component: <Store_page />,
  // },
  {
    name: "Contact us",
    route: "/website#contactus",
    // icon: <GitHubIcon />,
    // href: "https://www.github.com/creativetimofficial/material-kit-react",
  },
];

export default routes1;
