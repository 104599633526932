import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import { useSelector } from "react-redux";
import Website from "pages/Website";
import Solution_page from "pages/Website/components/Solution_Page";
import Store_page from "pages/Website/components/Store_Page";
import Success_Stories_Page from "pages/Website/components/Success_Stories_Page";
import routes1 from "routes";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const themeOption = useSelector((state) => state.general.themes);
  console.log("themeOption", themeOption)
  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Routes>
        {getRoutes(routes1)}
        <Route path="/website" element={<Website />} />
        <Route path="/successstories" element={<Success_Stories_Page />} />
        <Route path="/store" element={<Store_page />} />
        <Route path="*" element={<Solution_page />} />
        <Route path="/Solutions" element={<Solution_page />} />
        <Route path="/" element={<Navigate to="/website" />} />
      </Routes>
    </ThemeProvider >
  );
}
